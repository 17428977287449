.return-work {
  display: flex;
  flex-direction: column;
  height: 100vh;
  &__title {
    font-size: 1.5em;
    text-align: center;
    padding: 12px;
  }
  &__list {
    flex: 1;
    overflow: auto;
  }
  &__list-item {
    background: #ffffff;
    padding: 12px;
    display: flex;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .list-item-number {
      margin-right: 12px;
    }
  }
  &__footer {
    padding: 14px 100px;
    background: #ffffff;
  }
}
