.health-checkbox {
  display: flex;
  flex-wrap: wrap;
  & > .am-checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    .am-checkbox {
      margin-right: 4px;
    }
  }
}
