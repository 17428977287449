.scan-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .scan-code {
    width: 100%;
    margin: 20px 15px;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    padding: 20px 0;
    background: linear-gradient(
      0deg,
      rgba(0, 180, 255, 1) 0%,
      rgba(24, 144, 255, 1) 100%
    );
    box-shadow: 0px 8px 9px 2px rgba(0, 179, 255, 0.25);
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.success.am-modal-transparent {
  width: auto;
  max-width: 100vw;
}
