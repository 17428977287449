.subordinate-details {
  min-height: 100vh;
  &__error {
    color: #f5222d;
  }
  &__header {
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    font-size: 4.27vw;
    background: rgba(24, 144, 255, 1);
  }
  &__tabpane {
    background: #fff;
    margin-bottom: 90px;
  }
  &__list {
    &-item {
      padding: 18px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        &__employeeinfo {
          flex: 1;
        }
        &__view-detail {
          color: #1890ff;
        }
      }
      &__footer {
        color: #666666;
      }
    }
  }
  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 14px 100px;
    background: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
