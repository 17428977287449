.collect-infomation {
  background-color: #ffffff;
  height: 100vh;
  overflow: auto;
  .color-grey {
    color: #666666;
  }
  .color-blue {
    color: #1890ff;
    font-weight: bold;
  }
  &__step1 {
    text-align: center;
    margin-bottom: 100px;
    p {
      font-size: 4.27vw;
      line-height: 1.5;
    }
    &__header-title {
      padding: 5vh 0 2.97vh 0;
      font-weight: 500;
      font-size: 4.81vw;
    }
    &__tip {
      // margin-top: 2vh;
    }
    &__employee-infomation {
      box-shadow: 0px 1.07vw 1.87vw 0px rgba(0, 0, 0, 0.4);
      border-radius: 2.14vw;
      border: 1px solid rgba(232, 232, 232, 1);
      margin: 0 18.02vw;
      padding: 3.09vh 0;
    }
    &__footer {
      // position: fixed;
      // bottom: 0;
      // left: 0;
      // right: 0;
      padding: 2vh 2.02vw;
      display: flex;
      justify-content: space-between;
    }
  }
  &__step2 {
    margin-bottom: 15vh;
    &__questions {
      .question-title {
        padding: 2.45vh 0 2.45vh 5.74vw;
        font-size: 4.27vw;
        font-weight: 500;
        border: 1px solid #eeeeee;
        &.selected {
          background: #1890ff;
          color: #ffffff;
        }
        &.error {
          background: #f5222d !important;
          color: #ffffff !important;
        }
      }
      .am-list-item .am-list-line .am-list-extra {
        text-align: left;
        flex: 1;
      }
      .am-list-item .am-list-line .am-list-arrow-horizontal {
        visibility: hidden;
        display: none;
      }
    }

    &__footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 2.45vh 18.02vw;
      background: #ffffff;
      box-shadow: 0px -1.07vw 1.87vw 0px rgba(0, 0, 0, 0.1);
    }
  }
  &__step3 {
    text-align: center;
    line-height: 1.5;
    &__header-title {
      font-size: 5.07vw;
      padding-top: 5.49vh;
      padding-bottom: 4.65vh;
    }
    &__tip {
      font-size: 4.81vw;
      margin-bottom: 10.74vh;
    }
    &__footer {
      padding: 2.45vh 18.02vw;
    }
  }
}
// .voice-modal {
//   background-color: transparent;
// }
.mr-text-area {
  margin-right: 35px;
}
