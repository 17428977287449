html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  /* line-height: 1.5 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.collect-infomation {
  background-color: #ffffff;
  height: 100vh;
  overflow: auto; }
  .collect-infomation .color-grey {
    color: #666666; }
  .collect-infomation .color-blue {
    color: #1890ff;
    font-weight: bold; }
  .collect-infomation__step1 {
    text-align: center;
    margin-bottom: 100px; }
    .collect-infomation__step1 p {
      font-size: 4.27vw;
      line-height: 1.5; }
    .collect-infomation__step1__header-title {
      padding: 5vh 0 2.97vh 0;
      font-weight: 500;
      font-size: 4.81vw; }
    .collect-infomation__step1__employee-infomation {
      box-shadow: 0px 1.07vw 1.87vw 0px rgba(0, 0, 0, 0.4);
      border-radius: 2.14vw;
      border: 1px solid #e8e8e8;
      margin: 0 18.02vw;
      padding: 3.09vh 0; }
    .collect-infomation__step1__footer {
      padding: 2vh 2.02vw;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
  .collect-infomation__step2 {
    margin-bottom: 15vh; }
    .collect-infomation__step2__questions .question-title {
      padding: 2.45vh 0 2.45vh 5.74vw;
      font-size: 4.27vw;
      font-weight: 500;
      border: 1px solid #eeeeee; }
      .collect-infomation__step2__questions .question-title.selected {
        background: #1890ff;
        color: #ffffff; }
      .collect-infomation__step2__questions .question-title.error {
        background: #f5222d !important;
        color: #ffffff !important; }
    .collect-infomation__step2__questions .am-list-item .am-list-line .am-list-extra {
      text-align: left;
      -webkit-flex: 1 1;
              flex: 1 1; }
    .collect-infomation__step2__questions .am-list-item .am-list-line .am-list-arrow-horizontal {
      visibility: hidden;
      display: none; }
    .collect-infomation__step2__footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 2.45vh 18.02vw;
      background: #ffffff;
      box-shadow: 0px -1.07vw 1.87vw 0px rgba(0, 0, 0, 0.1); }
  .collect-infomation__step3 {
    text-align: center;
    line-height: 1.5; }
    .collect-infomation__step3__header-title {
      font-size: 5.07vw;
      padding-top: 5.49vh;
      padding-bottom: 4.65vh; }
    .collect-infomation__step3__tip {
      font-size: 4.81vw;
      margin-bottom: 10.74vh; }
    .collect-infomation__step3__footer {
      padding: 2.45vh 18.02vw; }

.mr-text-area {
  margin-right: 35px; }

.health-checkbox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .health-checkbox > .am-checkbox-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 4px 8px; }
    .health-checkbox > .am-checkbox-wrapper .am-checkbox {
      margin-right: 4px; }

.form {
  background-color: #ffffff;
  height: 100vh;
  overflow: auto;
  margin-bottom: 15vh; }
  .form .color-grey {
    color: #666666; }
  .form .color-blue {
    color: #1890ff;
    font-weight: bold; }
  .form__questions .question-title {
    padding: 2.45vh 0 2.45vh 5.74vw;
    font-size: 4.27vw;
    font-weight: 500;
    border: 1px solid #eeeeee; }
    .form__questions .question-title.selected {
      background: #1890ff;
      color: #ffffff; }
    .form__questions .question-title.error {
      background: #f5222d;
      color: #ffffff; }
  .form__questions .am-list-item .am-list-line .am-list-extra {
    text-align: left;
    -webkit-flex: 1 1;
            flex: 1 1; }
  .form__questions .am-list-item .am-list-line .am-list-arrow-horizontal {
    visibility: hidden;
    display: none; }
  .form__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2.45vh 18.02vw;
    background: #ffffff;
    box-shadow: 0px -1.07vw 1.87vw 0px rgba(0, 0, 0, 0.1); }

.mr-text-area {
  margin-right: 35px; }

.home__header {
  height: 100%;
  width: 100%; }

.home__button {
  padding: 2.5vh 0 2.5vh 0;
  text-align: center;
  color: #ffffff;
  border-radius: 2.13vw;
  font-size: 4.27vw;
  width: 100%;
  border: none; }

.home__content {
  text-align: center;
  background: #ffffff;
  margin-top: 2.03vh;
  padding: 5vh 0 5vh 0; }
  .home__content__date {
    font-weight: 500;
    font-size: 4.27vw;
    color: #000000; }
  .home__content__days {
    color: #1890ff;
    font-size: 4.27vw;
    font-weight: 500;
    margin-top: 1.88vh;
    margin-bottom: 5vh; }
  .home__content__days--nofill {
    color: #f5222d;
    font-size: 4.27vw;
    font-weight: 500;
    margin-top: 1.88vh;
    margin-bottom: 5vh; }
  .home__content__footer {
    padding: 0 18vw; }
  .home__content__counts {
    font-size: 4.27vw;
    padding: 0 18vw;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 3.13vh;
    line-height: 1.5; }
    .home__content__counts__percent-circle {
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);
      height: 13.87vw;
      width: 13.87vw;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      color: #ffffff;
      -webkit-justify-content: center;
              justify-content: center; }

.subordinate-details {
  min-height: 100vh; }
  .subordinate-details__error {
    color: #f5222d; }
  .subordinate-details__header {
    padding: 12px 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    color: #ffffff;
    font-size: 4.27vw;
    background: #1890ff; }
  .subordinate-details__tabpane {
    background: #fff;
    margin-bottom: 90px; }
  .subordinate-details__list-item {
    padding: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .subordinate-details__list-item__header {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      margin-bottom: 16px; }
      .subordinate-details__list-item__header__employeeinfo {
        -webkit-flex: 1 1;
                flex: 1 1; }
      .subordinate-details__list-item__header__view-detail {
        color: #1890ff; }
    .subordinate-details__list-item__footer {
      color: #666666; }
  .subordinate-details__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 14px 100px;
    background: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }

.return-work {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh; }
  .return-work__title {
    font-size: 1.5em;
    text-align: center;
    padding: 12px; }
  .return-work__list {
    -webkit-flex: 1 1;
            flex: 1 1;
    overflow: auto; }
  .return-work__list-item {
    background: #ffffff;
    padding: 12px;
    display: -webkit-flex;
    display: flex;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .return-work__list-item:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .return-work__list-item .list-item-number {
      margin-right: 12px; }
  .return-work__footer {
    padding: 14px 100px;
    background: #ffffff; }

.scan-code-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100%; }
  .scan-code-container .scan-code {
    width: 100%;
    margin: 20px 15px;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    padding: 20px 0;
    background: -webkit-linear-gradient(bottom, #00b4ff 0%, #1890ff 100%);
    background: linear-gradient(0deg, #00b4ff 0%, #1890ff 100%);
    box-shadow: 0px 8px 9px 2px rgba(0, 179, 255, 0.25);
    border-radius: 8px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }

.success.am-modal-transparent {
  width: auto;
  max-width: 100vw; }

