.home {
  &__header {
    height: 100%;
    width: 100%;
  }

  &__button {
    padding: 2.5vh 0 2.5vh 0;
    text-align: center;
    color: #ffffff;
    border-radius: 2.13vw;
    font-size: 4.27vw;
    width: 100%;
    border: none;
  }

  &__content {
    text-align: center;
    background: #ffffff;
    margin-top: 2.03vh;
    padding: 5vh 0 5vh 0;
    &__date {
      font-weight: 500;
      font-size: 4.27vw;
      color: #000000;
    }
    &__days {
      color: #1890ff;
      font-size: 4.27vw;
      font-weight: 500;
      margin-top: 1.88vh;
      margin-bottom: 5vh;
    }
    &__days--nofill {
      color: #f5222d;
      font-size: 4.27vw;
      font-weight: 500;
      margin-top: 1.88vh;
      margin-bottom: 5vh;
    }
    &__footer {
      padding: 0 18vw;
    }
    &__counts {
      font-size: 4.27vw;
      padding: 0 18vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3.13vh;
      line-height: 1.5;
      &__percent-circle {
        border-radius: 50%;
        // background: rgba(19, 194, 194, 1);
        background: rgba(0, 0, 0, 0.2);
        height: 13.87vw;
        width: 13.87vw;
        display: flex;
        align-items: center;
        color: #ffffff;
        justify-content: center;
      }
    }
  }
}
