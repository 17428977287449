.form {
  background-color: #ffffff;
  height: 100vh;
  overflow: auto;
  .color-grey {
    color: #666666;
  }
  .color-blue {
    color: #1890ff;
    font-weight: bold;
  }
  margin-bottom: 15vh;
  &__questions {
    .question-title {
      padding: 2.45vh 0 2.45vh 5.74vw;
      font-size: 4.27vw;
      font-weight: 500;
      border: 1px solid #eeeeee;
      &.selected {
        background: #1890ff;
        color: #ffffff;
      }
      &.error {
        background: #f5222d;
        color: #ffffff;
      }
    }
    .am-list-item .am-list-line .am-list-extra {
      text-align: left;
      flex: 1;
    }
    .am-list-item .am-list-line .am-list-arrow-horizontal {
      visibility: hidden;
      display: none;
    }
  }
  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2.45vh 18.02vw;
    background: #ffffff;
    box-shadow: 0px -1.07vw 1.87vw 0px rgba(0, 0, 0, 0.1);
  }
}

.mr-text-area {
  margin-right: 35px;
}
